import { createRouter, createWebHistory } from "vue-router";
import { createAuthGuard } from "@auth0/auth0-vue";
import { createAdminRoutes } from "./admin.js";
import { createMainAppRoutes } from "./main-app.js";
import { createSupportRoutes } from "../support-console/router/index.js";
import { isSupportConsole } from "../utils/is-support-console.js";
import { isRoleEditor } from "../utils/roles";

export function getRouters(app) {
  const authGuard = createAuthGuard(app);
  const routes = [
    {
      path: "/",
      name: "base",
      redirect: () => {
        if (isSupportConsole()) {
          return "/momentum-support";
        }
        return "/admin/notifications/ai-summary";
      },
    },

    // auth
    {
      path: "/auth/callback",
      name: "auth-callback",
      component: () => import("../pages/AuthCallback.vue"),
    },
    {
      path: "/password-login",
      name: "password-login",
      component: () => import("../pages/PasswordLogin.vue"),
    },

    ...createMainAppRoutes(app),
    ...createAdminRoutes(app),
    ...createSupportRoutes(app),

    // integration authentication success page
    {
      path: "/integration/authentication/success",
      name: "integration-authentication-success",
      component: () => import("../pages/IntegrationAuthenticationSuccess.vue"),
    },

    // integration authentication failure page
    {
      path: "/integration/authentication/failure",
      name: "integration-authentication-failure",
      component: () => import("../pages/IntegrationAuthenticationFailure.vue"),
    },

    // deal assist webhook success page
    {
      path: "/webhook/deal-assist/success",
      name: "dealassist-webhook-success",
      component: () => import("../pages/DealAssistWebhookSuccess.vue"),
    },

    {
      path: "/momentum-support/fake-meeting",
      name: "momentum-support-fake-meeting",
      component: () => import("../pages/momentum-support/MomentumSupportFakeMeeting.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Fake Meeting",
        layout: "AppLayoutDefault",
      },
    },

    //zendesk integration support
    {
      path: "/integration-zendesk-subdomain-input",
      name: "integration-zendesk-subdomain-input",
      component: () => import("../pages/IntegrationZendeskSubdomainInput.vue"),
      meta: {
        layout: "AppLayoutDefault",
      },
    },

    //non auth routes
    {
      path: "/sign-in",
      name: "UserLogin",
      component: () => import("../pages/SignIn.vue"),
      meta: {
        title: "Login",
        layout: "UserLogin",
      },
    },
    {
      path: "/configuration-error",
      name: "configuration-error",
      component: () => import("../pages/ConfigurationError.vue"),
      meta: {
        title: "Error",
        layout: "AppLayoutDefault",
      },
    },
    //share meeting
    {
      path: "/share/meeting/:meetingId",
      name: "share-meeting",
      component: () => import("../pages/MeetingShare.vue"),
      meta: {
        title: "Meeting",
        layout: "AppLayoutDefault",
      },
    },

    //this always needs to be last to catch any invalid paths
    //if route doesn't match from anything above serve a Page Not Found
    {
      path: "/:pathMatch(.*)*",
      name: "not-found",
      component: () => import("../pages/PageNotFound.vue"),
      meta: {
        layout: "AppLayoutDefault",
      },
    },
  ];

  const router = createRouter({
    history: createWebHistory(),
    routes,
  });

  router.beforeResolve((to, _from, next) => {
    if (to.path.startsWith("/admin/") && !isRoleEditor()) {
      // only editors can access /admin/ pages
      next({ name: "meetings" });
    } else if (isSupportConsole()) {
      // allow access to all pages on the support-console
      next();
    } else if (to.path.includes("/integration/authentication/success", "/integration/authentication/failure")) {
      // static pages, so no need to check for auth
      next();
    } else {
      next();
    }
  });

  router.beforeEach((to, _from, next) => {
    const id = to.params?.id ?? "";
    const title = to.meta?.title;
    const pageName = (title ?? to.name ?? "")
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(" ");
    document.title = `Momentum - ${pageName}${id ? " - " + id : ""}`.trim();
    next();
  });

  router.afterEach(() => {
    if (window.analytics) {
      window.analytics.page();
    }
  });

  return { router, routes };
}
