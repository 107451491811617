import { DateTime } from "luxon";
import { marked } from "marked";
import { v4 as uuidv4 } from "uuid";
import DOMPurify from "dompurify";
import { capitalize, isEmpty, sortBy } from "lodash";
import replaceAll from "string.prototype.replaceall";
import TurndownService from "turndown";
import { emojis } from "@tiptap-pro/extension-emoji";
import { SelectMixedOption } from "naive-ui/es/select/src/interface";
import { oneGraphifyName } from "@momentumhq/onegraph-utils";
import { TBinding, TBindingVersions } from "../types/bindings";
import { User, TSelectUserOption } from "../types/user";
import { ISalesforceField } from "../types/form";
import { TriggerMetadata } from "../metadata/triggers";
import { IntegrationFieldTypeEnum, TIntegrationObjectName } from "../types/integrations";
import { TSlackChannel } from "../types/slack";
import { TTriggerType } from "../types/triggers";
import { TAssistDefinition, AssistDefinitionCategoryEnum } from "../api/assist-definition/types";
import { TFilterConfig } from "../types/reusable-components";
import { ISalesforceFieldsForObject } from "../types/salesforce";
import { TObjectNode } from "../api/momentum";
import { TActionTypesEnum } from "../types/action-types";
import { TGif } from "../types/momentum";
import { isSupportConsole } from "../utils/is-support-console.js";
import { isLocalDevEnvironment } from "./import-env-vars.js";

const defaultProfileImage = "https://i1.wp.com/a.slack-edge.com/df10d/img/avatars/ava_0007-512.png?ssl=1";
const windowObj = window as any;
replaceAll.shim(); // will be a no-op if not needed

interface SelectedItem {
  id: number | string;
}

function limitString(string: string, maxCharacters: number, emptyMessage?: string): string {
  if (!string) {
    return emptyMessage;
  }

  if (string.length > maxCharacters) {
    return string.substring(0, maxCharacters) + "...";
  }
  return string;
}

function formatDate(date: string | Date, format: string): string {
  if (typeof date === "object" && date instanceof Date) {
    return DateTime.fromJSDate(date).toFormat(format);
  }
  return DateTime.fromISO(date + "Z").toFormat(format);
}

function formatDateTZ(date: string | Date, format: string): string {
  if (typeof date === "object" && date instanceof Date) {
    return DateTime.fromJSDate(date).toFormat(format);
  }
  return DateTime.fromISO(date).toFormat(format);
}

function formatTimestamp(timestamp: number, dateTimeString = false): string {
  if (dateTimeString) {
    return new Date(timestamp).toLocaleString();
  }
  return new Date(timestamp).toLocaleDateString();
}

function checkTime(i): string {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}

function formatTime(date: string): string {
  const hour = checkTime(DateTime.fromISO(date).hour);
  const minute = checkTime(DateTime.fromISO(date).minute);
  return `${hour}:${minute}`;
}

function getDayFromDate(date: string): string {
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const day = new Date(date).getDay();
  return days[day];
}

//Returns the output in the format of `01:00:00 hrs`
function calculateDuration(startTime: string, endTime: string): string {
  const start = new Date(startTime);
  const end = new Date(endTime);
  const duration = end.getTime() - start.getTime();
  return new Date(duration).toISOString().substring(11, 19) + " hrs";
}

function formatCurrency(amount: number): string {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
}

function filterSlackImages(slackProfile?: Object) {
  //need to add type for slackProfile
  //these are all the possible slack images in a slack profile
  const images = [
    "image_original",
    "image_1024",
    "image_512",
    "image_192",
    "image_72",
    "image_48",
    "image_32",
    "image_24",
  ];
  let profileImage;

  //if slack image key is not undefined then select that image
  for (const image of images) {
    if (slackProfile && slackProfile[image]) {
      profileImage = slackProfile[image];
      break;
    }
  }

  if (!profileImage) {
    profileImage = defaultProfileImage;
  }

  return profileImage;
}

function sanitizeHTMLContent(content: string) {
  DOMPurify.addHook("afterSanitizeAttributes", function (node) {
    if ("target" in node) {
      node.setAttribute("target", "_blank");
      node.setAttribute("rel", "noopener");
    }
  });
  return DOMPurify.sanitize(content);
}

function parseTag(tag: string): string {
  return convertToPascalCase(String(tag).replace(/_/g, " "));
}

//this filter is only here until Deal Approval and Simple Task is deprecated
function setCategory(slug: string) {
  let category: string;
  switch (slug) {
    case "deal-approval":
      category = "approval_custom";
      break;
    case "simple-task":
      category = "simple_task";
      break;
    default:
      category = "default";
  }

  return category;
}

function filterSelectedItems(
  fetchedData: Array<SelectedItem>,
  selectedData: Array<SelectedItem>,
  fetchedId?: string,
  selectedId?: string
): SelectedItem[] {
  //filter selected items from the fetched items so they can't be selected twice in the dropdown
  return fetchedData.filter((item) => {
    if (!fetchedId || !selectedId) {
      return !selectedData.find((selectedItem) => item.id === selectedItem.id);
    } else {
      //rare use case where the selectedData has a different ID key which doesn't match up with fetchData
      return !selectedData.find((selectedItem) => item[fetchedId] === selectedItem[selectedId]);
    }
  });
}

function convertHTMLToMarkdown(html: string, version?: string, retainNewLines?: boolean): string {
  let turndownService = new TurndownService();
  turndownService.addRule("list", {
    filter: ["li"],
    replacement: function (content: string) {
      return "- " + content.trim() + "\n";
    },
  });

  turndownService.addRule("strikethrough", {
    filter: ["s"],
    replacement: function (content: string) {
      return "~" + content + "~";
    },
  });

  turndownService.addRule("bold", {
    filter: ["strong"],
    replacement: function (content: string) {
      return "*" + content + "*";
    },
  });

  turndownService.addRule("italic", {
    filter: ["em"],
    replacement: function (content: string) {
      return "_" + content + "_";
    },
  });

  turndownService.addRule("slackLink", {
    filter: ["a"],
    replacement: function (content: string, node: any) {
      const url = node.getAttribute("href");
      const isContentSameAsUrl = areUrlsEqualCustom(content, url);
      return isContentSameAsUrl ? `${url}` : `<${url}|${content}>`;
    },
  });

  turndownService.addRule("bindingMention", {
    filter: ["span"],
    replacement: function (_content: string, node: any) {
      if (node.getAttribute("data-type") === "mention") {
        const isSlackMention = node.getAttribute("data-sf-user");
        const binding = node.getAttribute("data-id");

        if (node.getAttribute("preloader")) {
          return `{{${binding}}}`;
        }

        if (isSlackMention === "salesforce-user") {
          return `<@{{${binding}}}>`;
        }
        return version === "v2" ? `{{${binding} || 'N/A'}}` : `{{${binding}}}`;
      } else if (node.getAttribute("data-type") === "userMention") {
        const binding = node.getAttribute("data-id");
        if (node.getAttribute("preloader")) {
          return `{{${binding}}}`;
        }

        return `<@{{${binding} || 'N/A'}}>`;
      } else if (node.getAttribute("data-type") === "channelMention") {
        const binding = node.getAttribute("data-id");
        return `<#${binding}>`;
      } else if (node.getAttribute("data-type") === "emoji") {
        const binding = node.getAttribute("data-name");
        const originalBinding = node.getAttribute("data-original");
        return originalBinding ? `${originalBinding}` : node.innerText || `:${binding}:`;
      }
      return node.innerText;
    },
  });

  html = retainNewLines ? html.replaceAll("<p></p>", "<br>") : html;
  let markdown: string = turndownService.turndown(html);
  const findEmojiAlias = /:[^: ]+:/g;
  markdown = markdown.replaceAll(findEmojiAlias, (result) => {
    let alias: string = result;
    alias = alias.replaceAll("\\", "");
    return alias;
  });

  return markdown;
}

function markdownToHTML(
  markdown: string,
  options?: {
    bindings?: TBinding[];
    salesforceObject?: string;
    preview?: boolean;
    users?: TSelectUserOption[];
    channels?: TSlackChannel[];
    retainNewLines?: boolean;
  }
): string {
  let textParsed = options?.retainNewLines ? markdown.replaceAll("\n ", "<p></p>") : markdown;
  // eslint-disable-next-line no-useless-escape
  const slackLinkRegex = new RegExp(/<((?:\/|https?:\/\/)[\w.?=#*%&/-]+)\|([\w\s\-/[\]()']+)>/gm);
  textParsed = textParsed.replaceAll(slackLinkRegex, (regResult) => {
    const takeBracketsAway = regResult.slice(1, -1);
    const splitString = takeBracketsAway.split("|");
    const url = splitString[0];
    const name = splitString[1];
    return `[${name}](${url})`;
  });

  //Handle emojis earlier to avoid conflicts with italic
  const emojiBinding = new RegExp(/:[a-zA-Z0-9_+-]*(?:::[a-zA-Z0-9_+-]*)*:/gm);
  const emojiBindingMatches = textParsed.match(emojiBinding);
  if (Array.isArray(emojiBindingMatches)) {
    for (const binding of emojiBindingMatches) {
      textParsed = textParsed.replaceAll(binding, () => {
        const emoji = binding.slice(1, -1);
        const emojiToDisplay = emojis.filter((emo) => emo.name === emoji);
        if (emojiToDisplay.length) {
          return emojiToDisplay[0].emoji;
        }
        const question = emojis.filter((emo) => emo.name === "question")[0].emoji;
        return `<span data-type="emoji" data-name="${emoji}" data-original="${binding}">${question}</span>`;
      });
    }
  }

  const slackBoldRegex = new RegExp(/\*(.*?)\*/gm);
  textParsed = textParsed.replaceAll(slackBoldRegex, (regResult) => {
    const singleSlackMatch = new RegExp(/\*(.*?)\*/);
    // eslint-disable-next-line no-unused-vars
    const [_, text] = regResult.match(singleSlackMatch);
    return `**${text}**`;
  });

  const slackItalicRegex = new RegExp(/\b_([^:{{}}]+?)_/gm);
  textParsed = textParsed.replaceAll(slackItalicRegex, (regResult) => {
    const singleSlackMatch = new RegExp(/_([^:]+?)_/);
    // eslint-disable-next-line no-unused-vars
    const [_, text] = regResult.match(singleSlackMatch);

    if (text) {
      return `*${text}*`;
    }

    //just return the character if there is no text in between
    return _;
  });

  const slackStrikeRegex = new RegExp(/~(.*?)~/gm);
  textParsed = textParsed.replaceAll(slackStrikeRegex, (regResult) => {
    const singleSlackMatch = new RegExp(/~(.*?)~/);
    // eslint-disable-next-line no-unused-vars
    const [_, text] = regResult.match(singleSlackMatch);
    return `~~${text}~~`;
  });

  if (options?.users) {
    const slackUserBinding = new RegExp(/<@(.*?)>/gm);
    const userBindingMatches = textParsed.match(slackUserBinding);
    if (Array.isArray(userBindingMatches)) {
      for (const binding of userBindingMatches) {
        textParsed = textParsed.replaceAll(binding, () => {
          const user = findUserInBinding(binding, options?.users);
          const className = options?.preview ? "preview-mention-user" : "text-editor-mention-user";
          //Expected binding <@{{slack.userId['hello@momentum.io']}}> or <@{{slack.userId[object.field.id]}}>
          const bindingText = binding.slice(4, -3).replaceAll("\\", "");
          const dataId = user ? user.id : bindingText;
          const dataLabel = user ? user.momentumUser.name : bindingText;
          const text = user ? `@${user?.momentumUser?.slackUserProfile.real_name}` : binding;
          return `<span class="${className}" data-type="userMention" data-id="${dataId}" data-label="${dataLabel}">${text}</span>`;
        });
      }
    }
  }

  if (options?.channels) {
    const slackChannelBinding = new RegExp(/<#(.*?)>/gm);
    const channelBindingMatches = textParsed.match(slackChannelBinding);
    if (Array.isArray(channelBindingMatches)) {
      for (const binding of channelBindingMatches) {
        textParsed = textParsed.replaceAll(binding, () => {
          const channel = findChannelInBinding(binding, options?.channels);
          const className = options?.preview ? "preview-mention-channel" : "text-editor-mention-channel";
          const bindingText = binding;
          const dataId = channel ? channel.id : bindingText;
          const dataLabel = channel ? `# ${channel.name}` : bindingText;
          const text = channel ? `${dataLabel} (ID)` : binding;
          return `<span class="${className}" data-type="channelMention" data-id="${dataId}" data-label="${dataLabel}">${text}</span>`;
        });
      }
    }
  }

  if (options?.bindings) {
    const salesforceBinding = new RegExp(/\{{.+?\}}/gm);
    const sfBindingMatches = textParsed.match(salesforceBinding);
    if (Array.isArray(sfBindingMatches)) {
      for (const binding of sfBindingMatches) {
        const field = findFieldInBinding(binding, options?.bindings, options?.salesforceObject);
        textParsed = textParsed.replaceAll(binding, () => {
          const className = options?.preview ? "preview-mention" : "text-editor-mention";
          //Expected binding {{account.owner?.userRole?.id}}
          let dataId = field ? field.binding : binding.slice(2, -2);
          let dataLabel = field ? field.label : binding;
          let text = field ? `${field.subject}: ${field.label}` : binding;
          return `<span class="${className}" data-type="mention" data-id="${dataId}" data-label="${dataLabel}">${text}</span>`;
        });
      }
    }
  }

  return marked.parse(textParsed);
}

function areUrlsEqualCustom(url1: string, url2: string): boolean {
  // Replace escaped double underscores with unescaped double underscores
  const unescapedUrl1 = unescapeUnderscore(url1);
  const unescapedUrl2 = unescapeUnderscore(url2);

  return unescapedUrl1 === unescapedUrl2;
}

function unescapeUnderscore(url: string): string {
  return url.replace(/\\_/g, "_");
}

function findFieldInBinding(markdown: string, bindings: TBinding[], salesforceObject: string): TBinding {
  if (!bindings) return;
  let foundField: TBinding;
  let binding: string;
  let name: string;
  const salesforceBindingRegex = new RegExp(/\{{.+?\}}/gm);
  const findCustomFieldRegex = new RegExp(/\[.+?]/);
  const foundBinding = markdown.match(salesforceBindingRegex);

  if (Array.isArray(foundBinding)) {
    //take away surrounding brackets {{}}
    binding = foundBinding[0].slice(2, -2);
    const foundCustomField = binding.match(findCustomFieldRegex);

    if (foundCustomField) {
      //take away brackets and quotes to get the raw binding [""]
      const customName = foundCustomField[0].slice(2, -2);
      name = customName?.charAt(0)?.toUpperCase() + customName?.slice(1);
    } else {
      //This section is to identify additional objects
      const hardCodedFields = bindings.filter((field) => field["hardCoded"] && markdown.includes(field.name));
      if (hardCodedFields.length) {
        name = binding.split(".").splice(1).join(".");
      } else {
        const splitBinding = binding.split(".")[1];
        name = splitBinding?.charAt(0)?.toUpperCase() + splitBinding?.slice(1);
      }
    }

    foundField = bindings
      .filter((item) => item.name === name)
      .map((item) => {
        return {
          ...item,
          binding,
          subject: salesforceObject || "",
        };
      })[0];
    return foundField;
  } else {
    return null;
  }
}

//TODO: need to add a better return type for this function. This needs a bit of a refactor but is out of scope for this PR
function findUserInBinding(markdown: string, users: TSelectUserOption[]): TSelectUserOption {
  if (!users) return;
  let foundUser: TSelectUserOption;
  let binding: string;
  const slackUserBinding = new RegExp(/<@(.*?)>/gm);
  const innerSlackUserBinding = new RegExp(/\{{slack\.userId\[['"]?(.+?)['"]?\]\}}/gm);
  const foundBinding = slackUserBinding.exec(markdown);
  if (Array.isArray(foundBinding)) {
    /**
     * There are 2 regex.
     * First one (slackUserBinding) identifies whether it is a user binding with a <@(.*?)> match.
     * Second one (innerSlackUserBinding) picks up result from above,
     * matches the pattern {{slack.userId['user@domain.io']}} or {{slack.userId[opportunity.ownerId]}} and
     * tries to extract email 'user@domain.io' or opportunity.ownerId from it.
     */
    const innerBinding = innerSlackUserBinding.exec(foundBinding[1]);
    const foundUserId = foundBinding[1];
    if (Array.isArray(innerBinding)) {
      binding = innerBinding[1];
      if (binding.includes("@")) {
        foundUser = users
          .filter((item) => item?.momentumUser?.email === binding)
          .map((item) => {
            return {
              ...item,
              id: `slack.userId['${item.momentumUser.email}']`,
            };
          })[0];
        return foundUser;
      } else {
        //Building a fake user object out of binding `account.ownerId` to keep things easier on parsing
        const displayName = convertToTitleCase(binding.split(".").join(" "));
        return {
          momentumUser: {
            name: displayName,
            slackUserProfile: {
              real_name: displayName,
            },
            email: null,
            id: 123,
            isExternal: false,
            picture: null,
            slackUserId: null,
            label: null,
            fullName: null,
            role: null,
            slackUserName: null,
            userLicense: null,
            type: null,
          },
          label: displayName,
          type: "binding",
          id: `slack.userId[${binding}]`,
          disabled: false,
          value: null,
          momentumUserId: 123,
        };
      }
    }

    if (foundUserId) {
      foundUser = users.find((item: TSelectUserOption) => item.momentumUser.slackUserId === foundUserId);
      return foundUser;
    }
  } else {
    return null;
  }
}

function findChannelInBinding(markdown: string, channels: Array<TSlackChannel>): TSlackChannel {
  if (!channels) return;
  let foundChannel: TSlackChannel;
  const slackChannelBinding = new RegExp(/<#(.*?)>/gm);
  const foundBinding = slackChannelBinding.exec(markdown);
  if (Array.isArray(foundBinding)) {
    const foundChannelId = foundBinding[1];

    if (foundChannelId) {
      foundChannel = channels.filter((item: TSlackChannel) => item.id === foundChannelId)[0];
      return foundChannel;
    }
  } else {
    return null;
  }
}

function lowercaseFirstCharacter(name: string, trigger: string, custom: boolean): string {
  const variable = convertFirstCharToLowerCase(name);
  const triggerBinding = trigger.split("_")[0];

  if (custom) {
    return `${triggerBinding}.customFields['${name}']`;
  }

  return `${triggerBinding}.${variable}`;
}

function parseTriggerToSubject(triggerName: TTriggerType, referenceTo?: string): TIntegrationObjectName {
  return referenceTo ? referenceTo : new TriggerMetadata(triggerName).primaryObjectName();
}

function parseTriggerToObject(triggerName: TTriggerType, referenceTo?: string): string {
  return referenceTo ? referenceTo : oneGraphifyName(parseTriggerToSubject(triggerName));
}

function convertToTitleCase(str: string) {
  return str
    .split(" ")
    .map((word) => {
      return word.slice(0, 1).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

function configureField(value: ISalesforceField, trigger: TTriggerType): Record<string, any> {
  const subject = parseTriggerToSubject(trigger, null);
  let field = {
    label: value.label,
    name: value.name,
    custom: value.custom,
    binding: "",
    source: "",
    changesBinding: "",
    category: subject,
    referenceTo: value.referenceTo,
    type: value.type,
    picklistValues: ["combobox", "multipicklist", "picklist"].includes(value.type) ? value.picklistValues : [],
    id: uuidv4(),
  };

  const subjectValue = oneGraphifyName(subject);
  const bindings = configureBindings(subjectValue, value);
  field.binding = bindings.jsBinding;
  field.source = bindings.plainBinding;
  field.changesBinding = bindings.changesBinding;

  return field;
}

function configureBindings(subject: string, field: ISalesforceField): TBindingVersions {
  if (field.binding) {
    return {
      jsBinding: `{{${field.binding} || 'N/A'}}`,
      plainBinding: field.binding,
      changesBinding: field.changesBinding,
    };
  }
  if (field.custom) {
    return {
      jsBinding: `{{${subject}.customFields['${field.name}'] || 'N/A'}}`,
      plainBinding: `${subject}.customFields['${field.name}']`,
      changesBinding: `changes?.${field.name}?`,
    };
  }
  const name: string = convertFirstCharToLowerCase(field.name);
  return {
    jsBinding: `{{${subject}.${name} || 'N/A'}}`,
    plainBinding: `${subject}.${name}`,
    changesBinding: `changes?.${name}?`,
  };
}

const convertFirstCharToLowerCase = function (name: string) {
  return name ? name.charAt(0).toLowerCase() + name.slice(1) : "";
};

const formatOwner = function (users, trigger) {
  if (!trigger) {
    return;
  }
  //Pushing a custom item for supporting ownerId based on trigger type
  const binding = lowercaseFirstCharacter("OwnerId", trigger, false);
  const label = trimTrailingId(convertToTitleCase(binding.split(".").join(" ")));

  const id = `slack.userId[${binding}]`;
  if (users && users.find((user) => user.id === id)) {
    return;
  }

  return {
    email: binding,
    id: id,
    isExternal: true,
    name: label,
    picture: "",
    slackUserId: binding,
    label: label,
    slackUserProfile: {
      real_name: label,
    },
    type: "binding",
    value: id,
  };
};

const trimTrailingId = function (str) {
  return str.substring(0, str.length - 2);
};

function getAllActionsInfo() {
  return [
    {
      label: "Salesforce Attach File",
      description: "Attach a file to your Salesforce instance",
      value: "SalesforceAttachFile",
      icon: "salesforce",
      disabled: true,
    },
    {
      label: "Salesforce Chatter",
      description: "Log content to Salesforce Chatter",
      value: "SalesforceLogger",
      icon: "salesforce",
      disabled: false,
    },
    {
      label: "Salesforce Updater",
      description: "Update one more case fields",
      value: "SalesforceObjectUpdater",
      icon: "salesforce",
      disabled: true,
    },
    {
      label: "Salesforce Opportunity Update Dialog",
      description: "This will open a dialog to accept input and then update one more opportunity fields",
      value: "SalesforceOpportunityUpdateDialog",
      icon: "salesforce",
      disabled: true,
    },
    {
      label: "Slack Auto Invite Users",
      description: "Auto Invite selected users to a Slack channel",
      value: "SlackAutoInviteUsers",
      icon: "slack",
      disabled: false,
    },
    {
      label: "Slack Channel Creator",
      description: "Create a separate Slack channel",
      value: "SlackChannelCreator",
      icon: "slack",
      disabled: false,
    },
    {
      label: "Slack Channel Rename",
      description: "Rename a channel inside Slack",
      value: "SlackChannelRename",
      icon: "slack",
      disabled: true,
    },
    {
      label: "Slack Conversation Archiver",
      description: "Archive a channel inside Slack",
      value: "SlackConversationArchiver",
      icon: "slack",
      disabled: false,
    },
    {
      label: "Slack Channel Memorializer",
      description: "Memorialize a channel inside Slack",
      value: "SlackChannelMemorializer",
      icon: "slack",
      disabled: true,
    },
    {
      label: "Slack Message",
      description: "Send a message inside Slack",
      value: "SlackDirectMessage",
      icon: "slack",
      disabled: false,
    },
    {
      label: "Slack Deal Room Creator",
      description:
        "This will create a separate Slack channel for centralizing conversations and collaboration for the entire Deal Room",
      value: "SlackDealRoomCreator",
      icon: "slack",
      disabled: true,
    },
  ];
}

const getActionInfo = function (type: string) {
  return getAllActionsInfo().find((action) => action.value === type);
};

function getImageByIconType(type: string): string {
  const typeLowercase = type.toLowerCase();
  let image = "";
  switch (typeLowercase) {
    case "asana":
    case "gong":
    case "google_calendar":
    case "google_docs":
    case "google_drive":
    case "outreach":
    case "salesforce":
    case "salesloft":
    case "slack":
    case "stripe":
    case "zendesk":
      image = `${typeLowercase}.png`;
      break;
    default:
      image = "slack.png";
      break;
  }
  return image;
}

function getGifUrl(config: TGif): string {
  if (!config) {
    return null;
  }
  let extension = "";
  switch (config.size) {
    case "small":
      extension = "100.gif";
      break;
    case "large":
      extension = "giphy.gif";
      break;
    default:
      extension = "200.gif";
      break;
  }
  return `https://media.giphy.com/media/${config.id}/${extension}`;
}

function getImageByActionType(type: string): string {
  let image = "";
  switch (type) {
    case "SalesforceAttachFile":
    case "SalesforceLogger":
    case "SalesforceObjectUpdater":
    case "SalesforceOpportunityUpdateDialog":
      image = "salesforce.png";
      break;
    case "SlackAutoInviteUsers":
    case "SlackChannelCreator":
    case "SlackChannelRename":
    case "SlackConversationArchiver":
    case "SlackChannelMemorializer":
    case "SlackDirectMessage":
    case "SlackDealRoomCreator":
    case "Dialog":
    case "SlackInsight":
    case "CustomApprover":
      image = "slack.png";
      break;
  }
  return image;
}

function numOfDaysSince(date: string): number {
  const then = Date.parse(date);
  const now = Date.now();
  return (now - then) / (24 * 60 * 60 * 1000);
}

function timeSince(date: string): string {
  const then = Date.parse(date);
  const now = Date.now();
  const days = (now - then) / (24 * 60 * 60 * 1000);
  if (days > 365) {
    return `${Math.floor(days / 365)} years ago`;
  } else if (days > 30) {
    return `${Math.floor(days / 30)} months ago`;
  } else if (days > 1) {
    return `${Math.floor(days)} days ago`;
  } else {
    const hours = (now - then) / (60 * 60 * 1000);
    if (hours > 1) {
      return `${Math.floor(hours)} hours ago`;
    } else {
      const minutes = (now - then) / (60 * 1000);
      if (minutes > 1) {
        return `${Math.floor(minutes)} minutes ago`;
      } else {
        return "Just now";
      }
    } // end hours
  }
}

/**
 *
 * @param steps workflow steps
 * @returns sorted array of steps
 */
const topologicalSort = (steps) => {
  const stepKeys = Object.keys(steps);
  const visited = {}; //Object to store visited state for each step
  const stack = []; //Array to hold the sorted steps

  // Mark all the steps as not visited
  stepKeys?.forEach((step) => (visited[step] = false));
  // Call the recursive helper function to store
  // Topological Sort starting from all steps one by one
  stepKeys?.forEach((step) => {
    if (!visited[step]) {
      topologicalSortUtil(step, visited, stack, steps);
    }
  });

  //reverse of stack will give correct sort
  return stack.reverse();
};

// A recursive function used by topologicalSort
/**
 *
 * @param step: string This is a string containing step name
 * @param visited: {} This is an object holding the visit status of steps
 * @param stack: [] This is an array holding the sorted steps
 * @param steps: {} This is the actual steps object
 */
const topologicalSortUtil = (step, visited, stack, steps) => {
  // Mark the current step as visited.
  visited[step] = true;
  const adjSteps = steps[step].routes;
  // Recur for all the steps adjacent to this steps
  adjSteps?.forEach((adjStep) => {
    if (!visited[adjStep]) {
      topologicalSortUtil(adjStep, visited, stack, steps);
    }
  });

  // Push current step to the stack which stores result
  stack.push(step);
};

function convertFirstCharToUpperCase(name: string) {
  return name ? name.charAt(0).toUpperCase() + name.slice(1) : "";
}
/**
 *
 * @param trigger => case_created
 * @returns => Case Created
 */
function convertTriggerToTitleCase(trigger: string): string {
  const splitTrigger = trigger.split("_").join(" ");
  return convertToTitleCase(splitTrigger);
}

/**
 *
 * @param trigger => case_created
 * @returns => Salesforce Case Created
 */
function convertTriggerToReadableFormat(workflowMetadata: any): string {
  const trigger = workflowMetadata.triggerType;
  const wizard = workflowMetadata.wizard;
  if (
    trigger !== "salesforce.flow" &&
    workflowMetadata?.category !== AssistDefinitionCategoryEnum.SCHEDULED_NOTIFICATION
  ) {
    const allTriggers = getAllTriggers();
    const foundTrigger = allTriggers.find((item) => item.value === trigger);
    return foundTrigger ? foundTrigger.label : convertTriggerToTitleCase(trigger);
  } else if (workflowMetadata?.category === AssistDefinitionCategoryEnum.SCHEDULED_NOTIFICATION) {
    const getScheduledNotificationSteps = workflowMetadata?.wizard?.steps;
    let value = "";
    for (const step in getScheduledNotificationSteps) {
      if (getScheduledNotificationSteps[step].type !== TActionTypesEnum.SlackMessageSalesforceSOQLScroller) {
        value = trigger;
      } else if (getScheduledNotificationSteps[step].type === TActionTypesEnum.SlackMessageSalesforceSOQLScroller) {
        //add space before capital letters for long salesforce objects. Example: ActionLinkGroupTemplate => Action Link Group Template
        value = getScheduledNotificationSteps[step]?.args?.entity.replace(/([A-Z])/g, " $1").trim();
      }
    }
    return value;
  }

  const objectName = wizard?.salesforceFlow?.objectName || wizard?.jsonEditor?.salesforceFlow?.objectName;
  const recordTriggerType =
    wizard?.salesforceFlow?.recordTriggerType || wizard?.jsonEditor?.salesforceFlow?.recordTriggerType;
  let readableTriggerType = "";
  switch (recordTriggerType) {
    case "Update":
      readableTriggerType = "Updated";
      break;
    case "Create":
      readableTriggerType = "Created";
      break;
    case "CreateAndUpdate":
      readableTriggerType = "Created Or Updated";
      break;
  }
  return `${objectName} ${readableTriggerType}`;
}

/**
 *
 * @param stepsArr => Array of step names
 * @returns => Salesforce Case Created
 */
function filterPrimarySteps(stepsArr: Array<string>, allSteps: any): Array<string> {
  return stepsArr.filter((step) => {
    return !["FindFirst", "FindAll", "FindUser", "State"].includes(allSteps[step].type);
  });
}

function getAllTriggers(category?: string): Array<any> {
  let triggers = [
    { value: "account_created", label: "Account Created" },
    { value: "account_updated", label: "Account Updated" },
    { value: "account_upserted", label: "Account Created Or Updated" },
    { value: "contact_created", label: "Contact Created" },
    { value: "contact_updated", label: "Contact Updated" },
    { value: "contact_upserted", label: "Contact Created Or Updated" },
    { value: "event_created", label: "Event Created" },
    { value: "event_updated", label: "Event Updated" },
    { value: "event_upserted", label: "Event Created Or Updated" },
    { value: "lead_created", label: "Lead Created" },
    { value: "lead_updated", label: "Lead Updated" },
    { value: "lead_upserted", label: "Lead Created Or Updated" },
    { value: "opportunity_created", label: "Opportunity Created" },
    { value: "opportunity_updated", label: "Opportunity Updated" },
    { value: "opportunity_upserted", label: "Opportunity Created Or Updated" },
    { value: "case_created", label: "Salesforce Case Created" },
    { value: "case_updated", label: "Salesforce Case Updated" },
    { value: "case_upserted", label: "Salesforce Case Created Or Updated" },
    { value: "salesforce.flow", label: "Salesforce Flow" },
    { value: "sbqq_quote_created", label: "Salesforce CPQ Quote Created" },
    { value: "sbqq_quote_updated", label: "Salesforce CPQ Quote Updated" },
    { value: "sbqq_quote_upserted", label: "Salesforce CPQ Quote Created Or Updated" },
    { value: "task_created", label: "Task Created" },
    { value: "task_updated", label: "Task Updated" },
    { value: "task_upserted", label: "Task Created Or Updated" },
    { value: "user_created", label: "User Created" },
    { value: "user_updated", label: "User Updated" },
    { value: "user_upserted", label: "User Created Or Updated" },
    { value: "quote_created", label: "Quote Created" },
    { value: "quote_updated", label: "Quote Updated" },
    { value: "quote_upserted", label: "Quote Created Or Updated" },
  ];

  //We add any specific triggers based on the category
  switch (category) {
    case "notifications":
      triggers.push({ value: "case_comment_created", label: "Salesforce Case Comment Created" });
      break;
    case "approvals":
      triggers.push({ value: "request_form", label: "Deal Assist Form" });
      break;
    default:
      triggers.push(
        { value: "menu", label: "Deal Assist Menu" },
        { value: "salesforce_google_calendar_event", label: "Google Calendar Event" },
        { value: "case_comment_created", label: "Salesforce Case Comment Created" },
        { value: "reaction_to_message_added", label: "Reaction To Message Added" },
        { value: "gong_call_ended", label: "Gong Call Ended" }
      );
      break;
  }

  return sortArrBy(triggers, "label");
}

function sortArrBy(arr: Array<any>, key: string): Array<any> {
  return sortBy(arr, [key]);
}

function getOwnerUserRoleFields(): Array<ISalesforceField> {
  return [
    {
      custom: false,
      label: `Owner UserRole`,
      name: "owner?.userRole?.id",
      picklistValues: [],
      referenceTo: ["UserRole"],
      type: IntegrationFieldTypeEnum.reference,
      value: "owner?.userRole?.id",
      __typename: "SalesforceDescribeSObjectResultField",
      hardCoded: true,
    },
    {
      custom: false,
      label: `Owner UserRole Name`,
      name: "owner?.userRole?.name",
      picklistValues: [],
      referenceTo: [],
      type: IntegrationFieldTypeEnum.string,
      value: "owner?.userRole?.name",
      __typename: "SalesforceDescribeSObjectResultField",
      hardCoded: true,
    },
  ];
}

function getCreatedByUserRoleFields(): Array<ISalesforceField> {
  return [
    {
      custom: false,
      label: `CreatedBy UserRole`,
      name: "createdBy?.userRole?.id",
      picklistValues: [],
      referenceTo: ["UserRole"],
      type: IntegrationFieldTypeEnum.reference,
      value: "createdBy?.userRole?.id",
      __typename: "SalesforceDescribeSObjectResultField",
      hardCoded: true,
    },
    {
      custom: false,
      label: `CreatedBy UserRole Name`,
      name: "createdBy?.userRole?.name",
      picklistValues: [],
      referenceTo: [],
      type: IntegrationFieldTypeEnum.string,
      value: "createdBy?.userRole?.name",
      __typename: "SalesforceDescribeSObjectResultField",
      hardCoded: true,
    },
  ];
}

function getFormattedBinding(item, bindingStr): string {
  let binding = "";
  if (item.type === "currency") {
    binding = `{{utils.formatCurrency(${bindingStr}) || 'N/A'}}`;
  } else if (["date", "datetime"].includes(item.type)) {
    binding = `{{utils.formatDate(${bindingStr}, 'MMMM Do YYYY') || 'N/A'}}`;
  }
  return binding;
}

/**
 * This is a temporary method to have support for nested reference objects fields
 * @returns Array of additionalObject Fields
 */
function getAdditionalObjectFields(trigger: TTriggerType): Array<ISalesforceField> {
  const triggerObject = parseTriggerToObject(trigger);
  const rootObjectPrefix = triggerObject === "account" ? "" : "account?.";
  return [
    {
      custom: false,
      label: `CSM Name`,
      name: `${rootObjectPrefix}customerSuccessManager__r?.name`,
      picklistValues: [],
      referenceTo: [],
      type: IntegrationFieldTypeEnum.string,
      value: `${rootObjectPrefix}customerSuccessManager__r?.name`,
      __typename: "SalesforceDescribeSObjectResultField",
      hardCoded: true,
    },
    {
      custom: false,
      label: `Owner Name`,
      name: `${rootObjectPrefix}owner?.name`,
      picklistValues: [],
      referenceTo: [],
      type: IntegrationFieldTypeEnum.string,
      value: `${rootObjectPrefix}owner?.name`,
      __typename: "SalesforceDescribeSObjectResultField",
      hardCoded: true,
    },
  ];
}

function getAdditionalTaskFields(trigger: TTriggerType): Array<ISalesforceField> {
  const triggerObject = parseTriggerToObject(trigger);
  const rootObjectPrefix = triggerObject === "task" ? "" : "task?.";
  return [
    {
      custom: false,
      label: `What ID`,
      name: `${rootObjectPrefix}what?.id`,
      picklistValues: [],
      referenceTo: [],
      type: IntegrationFieldTypeEnum.string,
      value: `${rootObjectPrefix}what?.id`,
      hardCoded: true,
    },
    {
      custom: false,
      label: `What Name`,
      name: `${rootObjectPrefix}what?.name`,
      picklistValues: [],
      referenceTo: [],
      type: IntegrationFieldTypeEnum.string,
      value: `${rootObjectPrefix}what?.name`,
      hardCoded: true,
    },
    {
      custom: false,
      label: `What Salesforce Link`,
      name: `${rootObjectPrefix}what?.sobjectMetadata?.uiDetailUrl`,
      picklistValues: [],
      referenceTo: [],
      type: IntegrationFieldTypeEnum.string,
      value: `${rootObjectPrefix}what?.sobjectMetadata?.uiDetailUrl`,
      hardCoded: true,
    },
    {
      custom: false,
      label: `Who ID`,
      name: `${rootObjectPrefix}whoId`,
      picklistValues: [],
      referenceTo: [],
      type: IntegrationFieldTypeEnum.id,
      value: `${rootObjectPrefix}whoId`,
      hardCoded: true,
    },
  ];
}

function isSameObjectTrigger(oldTrigger: TTriggerType, newTrigger: TTriggerType): boolean {
  if (!TriggerMetadata.isValidTriggerName(oldTrigger) || !TriggerMetadata.isValidTriggerName(newTrigger)) {
    return false;
  }
  return new TriggerMetadata(oldTrigger).primaryObjectName() === new TriggerMetadata(newTrigger).primaryObjectName();
}

function convertToPascalCase(str: string) {
  return str
    .split(" ")
    .map((word) => {
      return word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
}

function getUserReferenceFields(fieldOptions, trigger) {
  return fieldOptions
    .filter((field) => field.referenceTo.includes("User"))
    .map((field) => {
      const label = field.label.replace("ID", "Email");
      const id = `${parseTriggerToObject(trigger)}.${convertFirstCharToLowerCase(field.name)}`;
      return {
        ...field,
        label,
        id,
        value: field.name,
        email: id,
        slackUserProfile: {
          image_72:
            "https://secure.gravatar.com/avatar/58610af39064179ce0c7cb97450a4538.jpg?s=24&d=https%3A%2F%2Fa.slack-edge.com%2Fdf10d%2Fimg%2Favatars%2Fava_0005-24.png",
          real_name: label,
        },
        type: "binding",
        disabled: false,
      };
    });
}

function getUsernameFromEmail(email: string): string {
  return email ? email.split("@")[0] : null;
}

function copyToClipboard(text) {
  if (!text) {
    return;
  }
  const content = text;
  if (!navigator.clipboard) {
    const element: any = document.createElement("textarea");
    element.value = content;
    element.setAttribute("readonly", "");
    element.style = { position: "absolute", left: "-999px" };
    document.body.appendChild(element);
    element.select();
    document.execCommand("copy", true);
    document.body.removeChild(element);
  }
  navigator.clipboard.writeText(content);
}

function getZeroHoursTimeOfToday(): number {
  const date = new Date();
  return date.setHours(0, 0, 0, 0);
}

function removeSpanStyle(content: string): string {
  const spanRegex = new RegExp(/<span\b style=[^>]*>(.*?)<\/span>/gm);
  return content.replace(spanRegex, "$1");
}

function identifyUser(user: User): void {
  // Record the name and email of the authenticated user, which may be an impersonator
  if (windowObj.analytics && user) {
    windowObj.analytics.identify(user.userId, {
      userId: user.userId,
      name: user.name,
      email: user.email,
    });
  }
}

function initializeSegment(writeKey: string): void {
  const analytics = (windowObj.analytics = windowObj.analytics || []);
  if (!analytics?.initialize) {
    if (analytics?.invoked) {
      window.console?.error?.("Segment snippet included twice.");
    } else {
      analytics.invoked = !0;
      analytics.methods = [
        "trackSubmit",
        "trackClick",
        "trackLink",
        "trackForm",
        "pageview",
        "identify",
        "reset",
        "group",
        "track",
        "ready",
        "alias",
        "debug",
        "page",
        "once",
        "off",
        "on",
        "addSourceMiddleware",
        "addIntegrationMiddleware",
        "setAnonymousId",
        "addDestinationMiddleware",
      ];
      analytics.factory = function (e) {
        return function () {
          const t = Array.prototype.slice.call(arguments);
          t.unshift(e);
          analytics.push(t);
          return analytics;
        };
      };
      for (let e = 0; e < analytics.methods.length; e++) {
        const key = analytics.methods[e];
        analytics[key] = analytics.factory(key);
      }
      analytics.load = function (key, e) {
        const t = document.createElement("script");
        t.type = "text/javascript";
        t.async = !0;
        t.src = "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";
        const n = document.getElementsByTagName("script")[0];
        n.parentNode.insertBefore(t, n);
        analytics._loadOptions = e;
      };
      analytics._writeKey = writeKey;
      analytics.SNIPPET_VERSION = "4.15.3";
      analytics.load(writeKey);
      analytics.page();
    }
  }
}

function isAFlowWorkflow(workflow: any): boolean {
  return workflow.wizard?.salesforceFlow || workflow.wizard?.jsonEditor?.salesforceFlow;
}

function getSfField(
  sfFields: ISalesforceFieldsForObject,
  objectName: string,
  fieldName: string
): { label: string; value: string; type: string } {
  if (!sfFields) {
    return;
  }
  const field = sfFields[objectName]?.filter((item) => item.value === fieldName);
  return field?.[0];
}

const confirmSupportAction = function (profile, workflowName) {
  if (!isSupportConsole()) return true;

  return confirm(`Are you sure you want to edit (${workflowName}) for (${profile.organizationName})`);
};

function escapeSpecialChars(str: string): string {
  return str.replace(/[[\]*+?{}.()^$|\\-]/g, "\\$&");
}

//This method sorts the opportunities by the reference ids array
function sortObjectsByRefIdsArray(
  refOpportunityIdsArray: string[],
  salesforceOpportunities: TObjectNode[]
): TObjectNode[] {
  const sortedObjects = [];
  refOpportunityIdsArray.forEach((id) => {
    const opportunity = salesforceOpportunities?.find((item) => item.id === id);
    if (opportunity) {
      sortedObjects.push(opportunity);
    }
  });
  return sortedObjects;
}

function getDateDaysAgo(days: number, refDate?: Date | number): Date {
  const date = refDate ? new Date(refDate) : new Date();
  date.setDate(date.getDate() - days);
  return date;
}

function getDateDaysAhead(days: number, refDate?: Date | number): Date {
  const date = refDate ? new Date(refDate) : new Date();
  date.setDate(date.getDate() + days);
  return date;
}

function getAgeInDays(date: Date | number): number {
  const emailDate = new Date(date);
  const today = new Date();
  const diffTime = Math.abs(today.getTime() - emailDate.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}

function computeGmailUrl(body: string, subject?: string, to?: string): string {
  return `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(to)}&su=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;
}

function readableTriggerType(assistDefinition: TAssistDefinition): string {
  let trigger: TTriggerType;

  if ("trigger" in assistDefinition.wizard) {
    trigger = assistDefinition.wizard.trigger;
  } else {
    trigger = assistDefinition.wizard.jsonEditor.trigger;
  }

  if (!trigger) return "N/A"; //we don't want the possible chance of a random WF with no trigger and breaking the UI
  return trigger
    .split("_")
    .map((word) => capitalize(word))
    .join(" ");
}

function trackSegmentEvent(eventName: string, properties?: any): void {
  if (windowObj.analytics && !isSupportConsole()) {
    windowObj.analytics.track(eventName, properties);
  }
}

function isLocalEnvironment(): boolean {
  return isLocalDevEnvironment === "true";
}

function setFilterOptions(type: string, options: SelectMixedOption[], filterConfig: TFilterConfig): void {
  const filter = filterConfig.dropdown.find((item) => item.type === type);
  if (filter) {
    filter.options = options;
  }
}

function hasPreLoaderContent(value: string): boolean {
  const preloaderRegex: any = new RegExp(/\[Preloader\[(.+?)\]Preloader\]/g);
  const preloaderMatches: string[] = value.match(preloaderRegex);
  return preloaderMatches?.length > 0;
}

function formatTagsFromSelectInput(selectedValues: any[], tags: any[], fetchedTagValues: any[]): any[] {
  const values = selectedValues.map((item) => item?.value);
  const uniqueIds: string[] = [];
  let foundTags: any[] = [];

  if (tags.length) {
    foundTags = [
      ...fetchedTagValues.filter((item) => values.includes(item.value)),
      ...tags.filter((item) => values.includes(item.value)),
      ...selectedValues,
    ];
  } else {
    foundTags = selectedValues;
  }

  foundTags = foundTags.filter((item) => {
    const isDuplicate = uniqueIds.includes(item.value);
    if (!isDuplicate) {
      uniqueIds.push(item.value);
      return true;
    }

    return false;
  });

  return foundTags;
}

function containsTrueValue(item: any): boolean {
  if (!item) {
    return false;
  }
  const hasTrueValue = Object.values(item).some((value) => {
    if (typeof value === "object") {
      return Object.values(value).some((innerValue) => innerValue === true);
    }
    return value === true;
  });
  return hasTrueValue;
}

function sumArrayValues(arr: number[]): number {
  return arr.reduce((acc, item) => acc + item, 0);
}

function downloadCSV(csvContent: string, filename: string): void {
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

function isNumber(text: string): boolean {
  if (text === null || text === undefined || isEmpty(text.trim()) || isNaN(Number(text))) {
    return false;
  }
  return true;
}

function removeUnderscores(text: string): string {
  return text.replace(/_/g, " ");
}

export {
  removeUnderscores,
  formatTagsFromSelectInput,
  isLocalEnvironment,
  escapeSpecialChars,
  limitString,
  defaultProfileImage,
  formatDate,
  formatDateTZ,
  formatTimestamp,
  formatTime,
  formatCurrency,
  getDayFromDate,
  filterSlackImages,
  sanitizeHTMLContent,
  parseTag,
  setCategory,
  filterSelectedItems,
  convertHTMLToMarkdown,
  markdownToHTML,
  lowercaseFirstCharacter,
  parseTriggerToSubject,
  parseTriggerToObject,
  convertToTitleCase,
  configureField,
  configureBindings,
  convertFirstCharToLowerCase,
  formatOwner,
  trimTrailingId,
  getAllActionsInfo,
  getActionInfo,
  getImageByActionType,
  getImageByIconType,
  numOfDaysSince,
  timeSince,
  topologicalSort,
  getGifUrl,
  convertFirstCharToUpperCase,
  convertTriggerToTitleCase,
  convertTriggerToReadableFormat,
  filterPrimarySteps,
  getAllTriggers,
  sortArrBy,
  getOwnerUserRoleFields,
  getCreatedByUserRoleFields,
  getFormattedBinding,
  getAdditionalObjectFields,
  getAdditionalTaskFields,
  isSameObjectTrigger,
  convertToPascalCase,
  getUserReferenceFields,
  getUsernameFromEmail,
  copyToClipboard,
  getZeroHoursTimeOfToday,
  removeSpanStyle,
  identifyUser,
  initializeSegment,
  isAFlowWorkflow,
  getSfField,
  confirmSupportAction,
  sortObjectsByRefIdsArray,
  getDateDaysAgo,
  getDateDaysAhead,
  computeGmailUrl,
  readableTriggerType,
  trackSegmentEvent,
  getAgeInDays,
  setFilterOptions,
  hasPreLoaderContent,
  containsTrueValue,
  sumArrayValues,
  downloadCSV,
  isNumber,
  calculateDuration,
};
