import gql from "graphql-tag";
import { DocumentNode } from "graphql";
import { BaseApi } from "../base";
import { TFeatureValue } from "./types";

export class FeaturesApi extends BaseApi {
  async getFeatureNames(): Promise<string[]> {
    type TData = { __type: { fields: { name: string }[] } };

    const query = gql`
      query GetOrganizationFeatureNames {
        __type(name: "organization_feature_set") {
          fields {
            name
          }
        }
      }
    `;

    try {
      const fields: TData = await this.query(query, {}, { fetchPolicy: "cache-first" });
      return fields.__type.fields.map((field) => field.name).filter((name) => name !== "organizationId");
    } catch (error) {
      await this.handleError(error);
    }
  }

  async getForSupport(organizationId: number): Promise<Record<string, TFeatureValue>> {
    type TVariables = { organizationId: number };
    type TData = { organization_by_pk: { featureSet: Record<string, TFeatureValue> } };

    try {
      const featureNames = await this.getFeatureNames();
      const query: DocumentNode = gql`
        query GetOrganizationFeaturesForSupport($organizationId: Int!) {
          organization_by_pk(id: $organizationId) {
            featureSet {
              ${featureNames.join("\n")}
            }
          }
        }
      `;

      const result: TData = await this.query<TData, TVariables>(query, { organizationId });
      return result.organization_by_pk.featureSet;
    } catch (error) {
      await this.handleError(error);
    }
  }

  async getAll(): Promise<Record<string, TFeatureValue>> {
    type TVariables = {};
    type TData = { organization_feature_set: Record<string, TFeatureValue>[] };

    try {
      const featureNames = await this.getFeatureNames();
      const query: DocumentNode = gql`
        query GetOrganizationFeatures {
          organization_feature_set {
            ${featureNames.join("\n")}
          }
        }
      `;

      const result: TData = await this.query<TData, TVariables>(query, {}, { fetchPolicy: "cache-first" });
      return result.organization_feature_set[0];
    } catch (error) {
      await this.handleError(error);
    }
  }

  async get<T extends TFeatureValue>(feature: string): Promise<T> {
    try {
      const features = await this.getAll();
      return features[feature] as T;
    } catch (error) {
      await this.handleError(error);
    }
  }

  async updateForSupport(variables: { organizationId: number; flag: string; value: TFeatureValue }): Promise<boolean> {
    type TVariables = { organizationId: number; features: Record<string, TFeatureValue> };
    type TData = { update_organization_feature_set: { affected_rows: number } };
    const query: DocumentNode = gql`
      mutation UpdateOrganizationFeatureFlagForSupport(
        $organizationId: Int!
        $features: organization_feature_set_set_input!
      ) {
        update_organization_feature_set(where: { organizationId: { _eq: $organizationId } }, _set: $features) {
          affected_rows
        }
      }
    `;

    try {
      const result: TData = await this.mutate<TData, TVariables>(query, {
        organizationId: variables.organizationId,
        features: {
          [variables.flag]: variables.value,
        },
      });
      this.postSuccess(`Feature ${variables.flag} updated successfully`);
      return result.update_organization_feature_set.affected_rows === 1;
    } catch (error) {
      await this.handleError(error);
    }
  }
}
